import React, { useEffect, useRef } from 'react'
import { motion, useInView, useAnimation } from 'framer-motion'

export default function Reveal({ children, clickedArrow, index }) {
    const ref = useRef(null);
    const isInView = useInView(ref, {once: true});

    const mainControls = useAnimation();

    useEffect(() => {
        if(isInView || index) {
            mainControls.start("visible");
        }
    }, [isInView, index]);

    const variants = {
        onView: {
            hidden: {opacity: 0, y: 75},
            visible: {opacity: 1, y: 0}
        },
        onClickForward: {
            hidden: {opacity: 0, x: 75},
            visible: {opacity: 1, x: 0}
        },
        onClickBack: {
            hidden: {opacity: 0, x: -75},
            visible: {opacity: 1, x: 0}
        }
    }

    const transitions = {
        onView: {
            duration: 0.82,
            delay: 0.25
        },
        onClick: {
            duration: 0.65,
            delay: 0.25
        }
    }

    function pickedVariant(clickedArrow) {
        switch(clickedArrow) {
            case 0: return "onClickBack";
            case 1: return "onClickForward";
            default: return "onView";
        }
    }

    function transitionVariants(clickedArrow) {
        switch(clickedArrow) {
            case 0 || 1: return "onClick";
            default: return "onView";
        }
    }

    return (
        <div ref={ref}>
            <motion.div
                key={index}
                variants={variants[pickedVariant(clickedArrow)]}
                initial="hidden"
                animate={mainControls}
                transition={transitions[transitionVariants(clickedArrow)]}
            >{children}</motion.div>
        </div>
    );
};