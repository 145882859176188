import '../css/Footer.css'

const Footer = () => {
    return (
    <div id="footer">
        {/* <p className="otherFoot">Copyright © 2024 Glob Gang Productions</p> */}
        <div id="footContent">
            <a href="https://soundcloud.com/globgangprod" target="_blank" rel="noopener noreferrer" className="footLinks">soundcloud</a>
            <a href="https://www.instagram.com/glob_gang_prod/" target="_blank" rel="noopener noreferrer" className="footLinks">instagram</a>
            <a href="https://www.youtube.com/@globgangprod/videos" target="_blank" rel="noopener noreferrer" className="footLinks">youtube</a>
        </div>
        <p className="otherFoot" id="emailFoot">email: globgangproductions@gmail.com</p>
    </div>
    );
}
 
export default Footer;