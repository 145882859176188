import { useState, useRef } from 'react'

import { useSwipeable } from 'react-swipeable'
import { motion } from 'framer-motion'

import { artist } from './ArtistBio'
import InfoContainer from './InfoContainer'

import arrow from '../pictures/arrow.svg'

import '../css/Body.css'

const Body = () => {
    const [ index, setIndex ] = useState(0);

    const VELOCITY = 2.1626;

    const handler = useSwipeable({
        onSwipedLeft: (eventData) => {
            console.log(eventData);
            if(eventData.velocity >= VELOCITY) onClickBack()
        },
        onSwipedRight: (eventData) => {
            console.log(eventData);
            if(eventData.velocity >= VELOCITY) onClickForward()
        },
        preventScrollOnSwipe: true,
        trackMouse: true
    });
    
    const ref = useRef(null);

    function onClickForward() {
        if(index + 1 === artist.length) {
            setIndex(0);
        } else {
            setIndex(index + 1);
        }
        ref.current = 1;
    };

    function onClickBack() {
        if(index == 0) {
            setIndex(artist.length - 1);
        } else {
            setIndex(index - 1);
        }
        ref.current = 0;
    };

    return (
        <div id="gradient-main">
            <div id="main" {...handler}>
                    <div className="btn-nav" id="back" onClick={onClickBack}>
                        <i className="arrows" />
                    </div>
                <motion.div
                    drag="x"
                    dragConstraints={{left: 0, right: 0}}
                    // dragElastic={1}
                    >
                    <InfoContainer name={artist[index].name} img={artist[index].img} sc={artist[index].sc} insta={artist[index].insta} x={artist[index].x} youtube={artist[index].youtube} tiktok={artist[index].tiktok} threads={artist[index].threads} text={artist[index].text} clickedArrow={ref.current} index={index} />
                </motion.div>
                    <div className="btn-nav" id="forward" onClick={onClickForward}>
                        <i className="arrows" />
                    </div>
                </div>
            <div id="indexed-dots">
                {
                    artist.map((a, num) => (
                        (num==index)?<i className="dots" id="currentDot" />:<i className="dots" />
                    ))
                }
            </div>
        </div>
    );
}
 
export default Body;